body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App .share div {
    float: left;
}


